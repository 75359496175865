/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Button, ButtonProps } from "@zapier/design-system";
import Link from "next/link";

const styleLink = css({
  display: "block",
  textDecoration: "none",
  // Make the link only as big as the button inside it, that way we don't have a
  // clickable area that looks like whitespace surrounding the button
  maxWidth: "min-content",
  // Button handles this for us
  outline: "none",
});

export interface LinkButtonProps extends ButtonProps {
  children: string;
  href: string;
  "data-testid"?: string;
}

export default function LinkButton({
  children,
  href,
  target,
  disabled,
  "data-testid": dataTestId,
  ...buttonProps
}: LinkButtonProps) {
  const opensInNewWindow =
    target === "_blank" ? `${children} (Opens in new window)` : undefined;

  const button = (
    <Button
      ariaLabel={opensInNewWindow}
      presentationOnly={true}
      disabled={disabled}
      {...buttonProps}
    >
      {children}
    </Button>
  );

  if (disabled) {
    return (
      <a
        css={styleLink}
        target={target}
        data-testid={dataTestId}
        aria-disabled="true"
      >
        {button}
      </a>
    );
  }

  return (
    <Link
      css={styleLink}
      href={href}
      passHref={true}
      target={target}
      data-testid={dataTestId}
    >
      {button}
    </Link>
  );
}

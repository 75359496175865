export interface ZapierZapTemplate {
  steps: {
    action: ZapierAction;
  }[];
  title: string;
  status: "draft" | "published";
}

export interface ZapierApp {
  id: string;
  type: string;
  image: string;
  links: {
    connect_new_authentication: string;
  };
  title: string;
  images: {
    url_16x16: string;
    url_32x32: string;
    url_64x64: string;
    url_128x128: string;
  };
  hex_color: string;
  categories: {
    slug: string;
  }[];
  description: string;
}

export interface ZapierAuthentication {
  id: string;
  app: string;
  type: string;
  title: string;
  is_expired: boolean;
}

export interface ZapierAction {
  action_type: "READ" | "WRITE";
  app: ZapierApp;
  description: string;
  id: string;
  is_instant: boolean;
  key: string;
  title: string;
  type: "action";
}

export interface ZapierInfoField {
  type: "info_field";
  id: string;
  description: string;
}

export interface ZapierInputField {
  type: "input_field";
  id: string;
  default_value: string;
  depends_on: string[];
  description: string;
  format?: "DATETIME" | "MULTILINE" | "PASSWORD" | "CODE" | "FILE" | "SELECT";
  invalidates_input_fields: boolean;
  is_required: boolean;
  items?: {
    type: "STRING" | "NUMBER" | "INTEGER" | "BOOLEAN" | "OBJECT";
  };
  placeholder: string;
  title: string;
  value_type: "STRING" | "NUMBER" | "INTEGER" | "BOOLEAN" | "ARRAY" | "OBJECT";
}

interface ZapierOutputFieldSample {
  [key: string]: string | ZapierOutputFieldSample;
}

export interface ZapierOutputField {
  type: "output_field";
  id: string;
  title: string;
  sample: string | ZapierOutputFieldSample;
}

export type ZapierField = ZapierInfoField | ZapierInputField;

// TODO: Can we put a better type on this?
export type ZapierInputs = Record<string, any>;

export interface InputChoice {
  id: string;
  type: "choice";
  label: string;
  value: string;
}

export type InputChoicesMap = Record<string, InputChoice[]>;

interface ZapierAPIErrorDetails {
  message: string;
  code: string;
}
export interface ZapierAPIError {
  status: number;
  code: string;
  title: string;
  detail?: string;
  meta: {
    source: "ZAPIER" | "THIRD_PARTY";
    full_details: Record<string, ZapierAPIErrorDetails[]>;
  };
}
export interface ZapierErrorResponse {
  errors: ZapierAPIError[];
}

export function isZapierErrorResponse(
  value: unknown,
): value is ZapierErrorResponse {
  return (
    typeof value === "object" &&
    value !== null &&
    "errors" in value &&
    Array.isArray(value.errors)
  );
}

export type ZapierStepTestData = Record<string, any>;

export interface ZapierGuidedRecipe {
  id: string;
  canonical_id: string;
  url: string;
  guided_recipe_url: string;
  min_url: string;
  webintent_url: string;
  slug: string;
  title: string;
  description: string;
  description_html: string;
  status: string;
  head_service: ZapierGuidedRecipeService;
  tail_service: ZapierGuidedRecipeService;
  services: ZapierGuidedRecipeService[];
  awesomeness: number;
  activation: number;
  used: number;
  middle_services: ZapierGuidedRecipeService[];
}

export interface ZapierGuidedRecipeService {
  id: string;
  canonical_id: string;
  current_implementation_id: string;
  name: string;
  slug: string;
  url: string;
  service_url: string;
  app_url: string;
  learn_more_url: string;
  image: string;
  images: {
    url_16x16: string;
    url_32x32: string;
    url_64x64: string;
    url_128x128: string;
  };
  description: string;
  banner: string;
  age_in_days: number;
  days_since_last_update: number;
  primary_color: string;
  is_premium: boolean;
  is_public: boolean;
  is_built_in: boolean;
  is_beta: boolean;
  is_featured: boolean;
  is_upcoming: boolean;
  is_requested: boolean;
  popularity: number;
  zap_usage_count: number;
  integration_overview_html: null;
  hashtag: string;
}

export class ZapierAPIError extends Error {
  readonly status: number;
  readonly messages: string[];

  constructor(messages: string[], status: number) {
    super(messages.join("; "));
    this.messages = [...messages];
    this.status = status;
  }

  get name() {
    return "ZapierAPIError";
  }
}

import { useQueryParam } from "./useQueryParam";

/**
 * Returns true if the query param is present and empty (e.g. `?foo` or `?foo=`)
 * or equal to "1" or "true" (case-insensitive). Defaults to `fallback` if the
 * param is missing.
 *
 * NOTE: Due to how Next.js works, the page is rendered WITHOUT query params
 * once before its rendered with query params. That means this hook will always
 * return `undefined` at least once. Take care to use `useEffect` or similar to
 * watch for changes, rather than using the return as an initial value to
 * `useState`
 */
export function useQueryParamBoolean(name: string, fallback: boolean): boolean {
  const param = useQueryParam(name);
  return normalizeQueryParamBoolean(param, fallback);
}

export function normalizeQueryParamBoolean(
  param: string | string[] | undefined,
  fallback: boolean,
): boolean {
  if (param === undefined) {
    return fallback;
  }
  const str = Array.isArray(param) ? param[param.length - 1] : param;
  const lower = str.toLowerCase();
  return lower === "" || lower === "1" || lower === "true";
}

// fallback to prod splitio client apikey
export const SPLIT_SDK_CLIENT_API_KEY =
  process.env.SPLIT_SDK_CLIENT_API_KEY ||
  "6qqp8g0qk7urrvfn0vh5f28ri93tjulroe0j";

// fallback to prod edge split item key
// https://vercel.com/zapier/partner/stores/edge-config/ecfg_rlea2gu8ohl3sxt6185bt6zya2jz/items
export const EDGE_CONFIG_SPLIT_ITEM_KEY =
  process.env.EDGE_CONFIG_SPLIT_ITEM_KEY || "split_partner_739";

// fallback to prod edge config
// https://vercel.com/zapier/partner/stores/edge-config/ecfg_rlea2gu8ohl3sxt6185bt6zya2jz/items
export const EDGE_CONFIG =
  process.env.EDGE_CONFIG ||
  "https://edge-config.vercel.com/ecfg_rlea2gu8ohl3sxt6185bt6zya2jz?token=b33e09a7-130d-4449-8159-244beb04752a";

// Use caution when using ZAPIER_ORIGIN for redirect URIs,
// or any place that should not be rewritten by the proxy in local development
export const ZAPIER_ORIGIN =
  process.env.NEXT_PUBLIC_ZAPIER_ORIGIN || "https://zapier.com";

// Safe to use for redirect URIs, since it is included in next.config.js'
// `staticEnvironmentVariables`
export const ZAPIER_PROXY_UPSTREAM =
  process.env.ZAPIER_PROXY_UPSTREAM || "https://zapier-staging.com";

export const CI_COMMIT_SHA = process.env.CI_COMMIT_SHA || "<dev>";

export const DEVELOPER_PLATFORM_ORIGIN =
  process.env.NEXT_PUBLIC_DEVELOPER_PLATFORM_ORIGIN ||
  "https://developer.zapier.com";

export const ZAPIER_API_ORIGIN =
  process.env.NEXT_PUBLIC_ZAPIER_API_ORIGIN || "https://api.zapier.com";

export const CI_COMMIT_REF_NAME = process.env.CI_COMMIT_REF_NAME || "";

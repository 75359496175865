import { ZapierAPIError } from "./ZapierAPIError";
import { isZapierErrorResponse } from "./zapierTypes";

// Common options to all requests
type BaseOptions = {
  url: string;
  accessToken?: string;
  signal?: AbortSignal;
};

// These methods require a body
type BodyOptions = {
  method: "POST" | "PUT";
  body: JSONBody;
};

// These methods may not have a body
type NoBodyOptions = {
  method?: "GET" | "DELETE";
};

// This body vs no body distinction is to help avoid making incorrect network
// requests, and to avoid forgetting the body when it's required.
type FetchJSONOptions = BaseOptions & (BodyOptions | NoBodyOptions);

export type JSONBody = JSONArray | JSONObject | JSONValue;
type JSONArray = JSONBody[];
type JSONObject = { [key: string]: JSONBody };
type JSONValue = string | number | boolean | null;

export async function fetchJSON<T>(options: FetchJSONOptions): Promise<T> {
  const headers = new Headers({ "Content-Type": "application/json" });
  if (options.accessToken) {
    headers.set("Authorization", `Bearer ${options.accessToken}`);
  }

  let response: Response | undefined;
  if ("body" in options) {
    response = await fetch(options.url, {
      headers,
      signal: options.signal,
      method: options.method,
      body: JSON.stringify(options.body),
    });
  } else {
    response = await fetch(options.url, {
      headers,
      method: options.method,
    });
  }

  // Handling for Workflow API error responses
  let json: T | undefined;
  try {
    json = await response.json();
  } catch (error) {
    // Handled later
  }

  if (response.status === 400) {
    if (isZapierErrorResponse(json)) {
      const zapierErrorJson = json;
      const errorStrings: string[] = [];
      // Convert ZapierErrorResponse into a series of error strings
      for (const error of zapierErrorJson.errors) {
        for (const [field, errorDetails] of Object.entries(
          error.meta.full_details,
        )) {
          const iterableErrorDetails = Array.isArray(errorDetails)
            ? errorDetails
            : [errorDetails];
          for (const errorDetail of iterableErrorDetails) {
            const errorString = `${error.title}: '${field}' ${errorDetail?.message ?? errorDetail}`;
            errorStrings.push(errorString);
          }
        }
      }
      if (errorStrings.length > 0) {
        throw new ZapierAPIError(errorStrings, response.status);
      }
    }
  } else if (response.status === 401 || response.status === 403) {
    if (isZapierErrorResponse(json)) {
      const zapierErrorJson = json;
      const zapierAccessTokenError = zapierErrorJson.errors[0];
      throw new ZapierAPIError(
        [`${zapierAccessTokenError.title}: ${zapierAccessTokenError.detail}`],
        response.status,
      );
    }
  }

  if (!response.ok || json === undefined) {
    throw new Error(response.statusText);
  }

  return json;
}

import { useSessionStorage } from "usehooks-ts";
import { Filterable } from "src/utils/filterable";

type Mode = "editing" | "done";
type Theme = "light" | "dark" | "auto";
type WorkflowType = "popular" | "specific";

interface ZapTemplate {
  id: string;
  title: string;
}

interface App {
  name: string;
  slug: string;
}

interface Category extends Filterable {
  title: string;
}

interface WorkflowElementGenerator {
  // User/app info required for generator
  clientId: string;
  integrationId: string;
  serviceSlug: string;

  // Generator configs
  appCategories: Category[];
  appExclusions: App[];
  appLimit?: number;
  appSearchBarDisplay?: boolean;
  bgColor: string;
  introCopyDisplay?: boolean;
  mode: Mode;
  showTemplates: boolean;
  theme: Theme;
  workflowType: WorkflowType;
  templateCallToActionDisplay?: boolean;
  zapCreateFromScratchDisplay?: boolean;
  templatesLimit?: number;
  manageZapsDisplay?: boolean;
  templateStyle?: string;
  templates: ZapTemplate[];
  templatesQuery: string;
}

interface UseWorkflowElementReturn {
  elementConfig: WorkflowElementGenerator | null;
  defaultConfig: WorkflowElementGenerator;
  setGeneratorProperty: <K extends keyof WorkflowElementGenerator>(
    key: K,
    value: WorkflowElementGenerator[K],
  ) => void;
}

const defaultConfig: WorkflowElementGenerator = {
  clientId: "",
  integrationId: "",
  serviceSlug: "",
  theme: "light",
  mode: "editing",
  workflowType: "popular",
  appLimit: undefined,
  templates: [],
  templatesQuery: "",
  appExclusions: [],
  appCategories: [],
  showTemplates: true,
  templatesLimit: undefined,
  templateStyle: undefined,
  introCopyDisplay: true,
  manageZapsDisplay: undefined,
  appSearchBarDisplay: undefined,
  zapCreateFromScratchDisplay: undefined,
  templateCallToActionDisplay: undefined,
  bgColor: "#ffffff",
};

export function useWorkflowElement(): UseWorkflowElementReturn {
  const [elementConfig, setElementConfig] =
    useSessionStorage<WorkflowElementGenerator | null>(
      "workflow.generator",
      null,
      { initializeWithValue: false },
    );

  const setGeneratorProperty = <K extends keyof WorkflowElementGenerator>(
    key: K,
    value: WorkflowElementGenerator[K],
  ) => {
    setElementConfig((prev) => {
      if (!prev) {
        return { ...defaultConfig, [key]: value };
      }
      return { ...prev, [key]: value };
    });
  };

  return { elementConfig, defaultConfig, setGeneratorProperty } as const;
}
